.game-settings-section {
  margin: 15px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.settings-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 8px;
  padding-left: 0px;
}

.setting-item {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  height: 36px;
  padding-left: 10px;
}

.setting-item label {
  font-size: 1.1rem;
  color: #000000;
  min-width: 30px;
  text-align: left;
  font-weight: 500;
  line-height: 1;
  flex-shrink: 0;
  margin-right: -8px;
  margin-left: -5px;
}

.setting-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 4px 0px;
  width: 200px;
  flex-shrink: 0;
  height: 32px;
  margin-left: 8px;
}

.current-value {
  text-align: center;
  font-size: 1.1rem;
  color: #333;
  width: 120px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  
}

.arrow-button {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
  font-size: 1.1rem;
  min-width: 20px;
  height: 24px;
}

.arrow-button:hover {
  color: #333;
}

.arrow-button:active {
  transform: scale(0.95);
} 