@import url('https://cdn.jsdelivr.net/gh/sun-typeface/SUIT@2/fonts/variable/woff2/SUIT-Variable.css');

html {
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
  position: fixed;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  font-family: 'SUIT Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
