@font-face {
  font-family: 'ONE-Mobile-POP';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-POP.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  
}

@font-face {
  font-family: 'Orange Juice';
  src: url('./fonts/orange_juice.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://cdn.jsdelivr.net/gh/sun-typeface/SUIT@2/fonts/variable/woff2/SUIT-Variable.css');


html {
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.App {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: env(safe-area-inset-top) 
           env(safe-area-inset-right)
           env(safe-area-inset-bottom)
           env(safe-area-inset-left);
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}



.logo {
  font-family: 'Orange Juice', cursive;
  font-size: clamp(4rem, 12vw, 5.5rem);
  color: #000000;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: normal;
}

.top-left {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0.5rem;
  font-size: 1.2rem;
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 3px 3px 0 #000000;
  transition: all 0.2s;
  z-index: 10;
}

.top-left:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 #000000;
}

button {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #ffffff;
  color: #000000;
  padding: 10px 10px;
  line-height: 2;
  width: auto;
  height: auto;
  border: 4px solid #000000;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2em;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
}

button:hover {
  background: #FFE5E5;
}

button:nth-child(2n):hover {
  background: #E5F2FF;
}

button:nth-child(3n):hover {
  background: #E5FFE5;
}

button:nth-child(4n):hover {
  background: #FFE5FF;
}

button:nth-child(5n):hover {
  background: #FFFFE5;
}

button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.create-room {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 2rem;
  border-radius: 50px;
  position: relative;
  border: 3px solid #000000;
  box-shadow: 4px 4px 0 #000000;
  transition: all 0.2s ease;
  margin: 20px auto;
  width: 300px;
}

.create-room::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px solid #000;
  border-radius: 28px;
  z-index: -1;
}

.create-room::after {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  border: 2px solid #000;
  border-style: dashed;
  border-radius: 32px;
  z-index: -2;
}

.create-room input::placeholder
{  margin: 20px auto;
  color: rgb(171, 171, 171);
}

.create-room input
{  margin: 20px auto;
  color: black;
  width: 90%;
  height: 50px;
  text-align: center;
  font-size: x-large;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border: 3px solid #000000;
  border-radius: 50px;
  box-shadow: 4px 4px 0 #000000;
  transition: all 0.2s ease;
  background: #ffffff;
}

.create-room input:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 #000000;
}

.create-room input:focus {
  outline: none;
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 #000000;
}

.create-room button
{
  margin: 20px auto;
  width: 70%;
  text-align: center;
  font-size: x-large;
  border: 3px solid #000000;
  border-radius: 50px;
  box-shadow: 4px 4px 0 #000000;
  transition: all 0.2s ease;
}

.create-room button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 #000000;
}

.create-room button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 #000000;
}

.create-room button
{
  margin-top: 30px;
  margin-bottom: 30px;
  margin: 0 auto;
  width: 70%;
  text-align: center;
  font-size: x-large;
}


.create-room, 
.join-room, 
.waiting-room, 
.game-settings {
  background: rgba(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 4px 4px 0 #000000;
  text-align: center;
  width: 80%;
  max-width: 400px;
  justify-content: center;
  -webkit-user-select: none;  /* Disable text selection */
  -moz-user-select: none;     /* Disable text selection */
  -ms-user-select: none;      /* Disable text selection */
  user-select: none;          /* Disable text selection */
}

.waiting-room {
  position: relative;
  width: 90%;
  max-width: 400px;
  text-align: center;
  padding: 2rem;
  border-radius: 24px;
  background: #ffffff;
  border: 3px solid #000;
  margin: 20px auto;
  margin-bottom: 80px;
}

.waiting-room-code {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 1rem 0;
}

.waiting-room-code span {
  width: 28px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #000;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.waiting-room-code span::before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.waiting-room-code span::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  height: 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.players-list {
  max-width: 800px;
  background: transparent;  /* 배경도 투명하게 변경 */
  border: none;  /* 테두리 제거 */
  border-radius: 24px;
  padding: 1rem;
  margin: 1rem auto;
  box-shadow: none;  /* 그림자 제거 */
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

.players-list::-webkit-scrollbar {
  display: none;
}

.players-list li {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background: #fff;
  border: 2px solid #000;
  border-radius: 12px;
  box-shadow: 3px 3px 0 rgba(237, 225, 225, 0.2);
  min-width: max-content;
  flex-shrink: 0;
  padding: 0.4rem 0.8rem;
  box-shadow: 3px 3px 0 #000000;

}



.player-name {
  font-size: 1.1rem;
  color: #000;
  margin-left: 8px;
  white-space: nowrap;
}


.start-game {
  width: 90%;
  max-width: 400px;
  height: 50px;
  border-radius: 25px;
  font-size: 1.5rem;
  background-color: #000000;
  color: #ffffff;
  border: 3px solid #000;
  margin: 0 auto;
}


.start-game:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #000000;  /* hover 시에도 검은색 유지 */
  color: #ffffff;  /* hover 시에도 흰색 유지 */
}

.start-game:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
}


.popup-layer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
  border-radius: 24px;
  border: 3px solid #000;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
}

.save-button {
  margin-top: 1rem;
  width: auto;
  min-width: 120px;
}

.waiting-room p {
  font-size: 1.1rem;
  color: #666;
  margin: 1rem 0;
}

.waiting-room h2, .waiting-room h3 {
  color: #000;
  margin: 1rem 0;
}

.create-room {
  margin: 20px auto;
  width: 300px;
}

.code-input {
  position: relative;
  width: 280px; /* 8칸 * 35px */
  margin-bottom: 20px;
  margin: 0 auto;
}

.code-input .hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  opacity: 0;
  z-index: 10; /* Ensure the input field is above the boxes */
}

.input-boxes {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 5; /* Ensure the boxes are below the input field */
  gap: 3px;
}

.digit-box {
  margin: 20px auto;
  color: black;
  text-align: center;
  font-size: xx-large;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 3px;
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.digit-box .placeholder-box {
  font-size: x-small;
}

.waiting-room-buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -70px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.waiting-room .waiting-room-code {
  font-size: xxx-large;
}

.waiting-room .players-list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none; /* Remove the default list bullets */
  padding: 0;
  max-height: 150px; /* Set the height for the list */
  overflow-x: none; /* Add vertical scroll if the list overflows */
  overflow-y: auto; 
  border-radius: 5px;
  padding: 5px;
  justify-content: center;
}


.players-list li .player-id {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%; /* Make it circular */
  margin-right: 3x; /* Space between circle and player name */
  font-weight: bold;
  color: #000;
  font-size: large;
  margin-right: 10px;
}

.players-list li .player-name {
  text-align: left;
  margin-left: 3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.waiting-room-info {
  max-width: 336px;
  max-height: 280px;
  width: 336px;
  /* height: 280px; */
  height: auto;
  min-width: 100%;
  min-height: none;
  margin-top: 20px;

}


.popup_error {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 2rem;
    border-radius: 24px;
    border: 3px solid #000;
    box-shadow: 4px 4px 0 #000;
    z-index: 1000;
    width: 60%;
    font-size: 1.5rem;
}

.warning-icon {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  width: 3rem;
  height: 3rem;
  border: 3px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5rem auto;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
}
  

/*info*/
.gamescreen-info {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 300px;
  height: 50px;
  background-color: transparent;
}

@media (min-width: 750px) {
.gamescreen-info {
  width: 728px;
  height: 90px;
}
}



@media (max-width: 901px) {
  .App {
    max-width: 820px;
    border: 0px;
    border-radius: 0px;
    height: 100vh;
    max-height: 1180px;
  }


  .create-room, 
  .join-room, 
  .waiting-room, 
  .game-settings,
  .game-mode {
      width: 90%;
      margin: 10px;
  }

  .game-mode {
      width: 100%;
  }
}

/* 초기 화면 컨테이너 스타일 */
.initial-screen {
  width: 90%;
  max-width: 400px;
  text-align: center;
  padding: 2rem;
  border-radius: 24px;
  background: #ffffff;
  position: relative;
  margin: 20px auto;
  border: 3px solid #000000;
  box-shadow: 4px 4px 0 #000000;
  display: flex;
  flex-direction: column;  /* 세로 방향으로 정렬 */
  align-items: center;     /* 가운데 정렬 */
  gap: 20px;              /* 버튼 사이 간격 */
}


/* 코너 점 스타일 */
.initial-screen .corner {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
}

/* 버튼 스타일 수정 */
.initial-screen button {
  display: block;
  padding: 0.8rem 2rem;
  font-size: 2rem;       /* 글씨 크기 증가 */
  color: #000000;
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s;
  box-shadow: 4px 4px 0 #000000;
  margin: 1rem 0;
  width: auto;
  min-width: 200px;
}

.initial-screen button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 #000000;
}

.initial-screen button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 #000000;
}

/* 뒤로가기 버튼 스타일 */
.top-left {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0.5rem;
  font-size: 1.2rem;
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 3px 3px 0 #000000;
  transition: all 0.2s;
  z-index: 10;
}

.top-left:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 #000000;
}

/* 반응형 스타일 */
@media (max-width: 767px) {
  .initial-screen {
    width: 85%;
    padding: 1.5rem;
  }

  .initial-screen button {
    padding: 0.3rem 1.5rem;
    font-size: 1.8rem;
    min-width: 250px;
  }
}

@media (max-height: 600px) {
  .initial-screen {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .waiting-room {
    width: 85%;
    padding: 1.5rem;
    margin: 15px auto;
    margin-bottom: 70px;
  }
  
  .waiting-room-code {
    gap: 6px;
  }
  
  .waiting-room-code span {
    width: 24px;
    height: 32px;
    font-size: 1.1rem;
  }

  .start-game {
    width: 85%;
  }

  .waiting-room-buttons {
    bottom: -60px;
  }
}

/* 방 코드 컨테이너 */
.room-code-container {
  margin: 1rem 0;
}



/* 방 코드 카드 컨테이너 */
.room-code-cards {
  display: grid;
  grid-template-columns: repeat(4, auto);  /* 4열 그리드 */
  grid-template-rows: repeat(2, auto);     /* 2행 그리드 */
  gap: 4px;    /* 카드 간격 */
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
}

/* 개별 코드 카드 스타일 */
.code-card {
  width: 35px;       /* 카드 너비 감소 */
  height: 45px;      /* 카드 높이 감소 */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 3px solid #000;
  border-radius: 6px;
  font-weight: bold;
  font-size: 2rem;
  position: relative;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
  line-height: 1;    /* 줄 간격 최소화 */
}

/* 모바일 대응 */
@media (max-width: 480px) {
  .code-card {
    width: 40px;
    height: 50px;
    font-size: 1.8rem;
  }
  
  .room-code-cards {
    gap: 4px;
  }
  
  .room-code-container h2 {
    font-size: 2rem;
  }

  .create-room, 
  .join-room, 
  .waiting-room, 
  .game-settings,
  .initial-screen {
    max-height: 85vh;
    padding: 1rem;
  }

}


