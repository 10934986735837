.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.game-settings {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  position: relative;
}

.settings-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}

.setting-item {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 36px;
}

.setting-item label {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 500;
  line-height: 1;
  flex-shrink: 0;
}

.setting-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 4px 2px;
  width: 180px;
  height: 32px;
}

.current-value {
  text-align: center;
  font-size: 1.1rem;
  color: #000;
  width: 150px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}

.arrow-button {
  background: none;
  border: none;
  padding: 0px;
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
  font-size: 1.1rem;
  min-width: 20px;
  height: 24px;
  margin: 0 2px;
}

.arrow-button:hover {
  transform: scale(1.1);
}

.arrow-button:active {
  transform: scale(0.95);
}

.settings-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.start-button {
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background-color: #000000;
  border: none;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  padding: 0;
}

.start-button svg {
  width: 20px;
  height: 20px;
  color: white;
}

.start-button:hover {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.start-button:active {
  transform: translate(4px, 4px);
  box-shadow: none;
}

.start-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* CircularProgress 중앙 정렬 */
.start-button .MuiCircularProgress-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-settings h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.4rem;
  color: #000;
}

.waiting-message {
  text-align: center;
  padding: 20px;
  font-size: 1.1rem;
  color: #666;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: none;
  border: 2px solid #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.2s;
}

.close-button svg {
  width: 20px;
  height: 20px;
  color: #000;
}

.close-button:hover {
  background-color: #000;
}

.close-button:hover svg {
  color: white;
}

.close-button:active {
  transform: scale(0.95);
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
