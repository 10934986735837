@font-face {
  font-family: 'Orange Juice';
  src: url('../fonts/orange_juice.ttf') format('truetype');
}

.logo-text {
  font-family: 'Orange Juice', cursive;
  font-size: clamp(3.5rem, 10vw, 4.5rem);
  color: #000000;
  margin-bottom: 1rem;
  line-height: 1;
}


.landing-page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  background-image: radial-gradient(#000000 0.5px, transparent 0.5px);
  background-size: 15px 15px;
  padding: 20px;
  box-sizing: border-box;
}

.landing-container {
  width: 90%;
  max-width: 400px;
  text-align: center;
  padding: 2rem;
  border-radius: 24px;
  background: #ffffff;
  position: relative;
  border: 3px solid #000;
  margin: 20px auto;
}

.landing-container::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 2px solid #000;
  border-radius: 28px;
  z-index: -1;
}

.landing-container::after {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  border: 2px solid #000;
  border-style: dashed;
  border-radius: 32px;
  z-index: -2;
}

.landing-container .corner {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 50%;
}

.landing-container .corner-tl {
  top: -4px;
  left: -4px;
}

.landing-container .corner-tr {
  top: -4px;
  right: -4px;
}

.landing-container .corner-bl {
  bottom: -4px;
  left: -4px;
}

.landing-container .corner-br {
  bottom: -4px;
  right: -4px;
}

.title {
  font-size: clamp(2rem, 7vw, 2.8rem);
  color: #000000;
  font-weight: normal;
  line-height: 1.3;
  margin: 1rem 0;
  padding: 0 10px;
}

.play-button {
  display: inline-block;
  padding: 0.8rem 2rem;
  font-size: clamp(1rem, 5vw, 1.2rem);
  font-weight: 600;
  color: #000000;
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s;
  box-shadow: 4px 4px 0 #000000;
  position: relative;
  margin-top: 1rem;
  width: auto;
  min-width: 200px;
}

.play-button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 6px 6px 0 #000000;
}

.play-button:active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px 0 #000000;
}

/* 데코레이션 요소 추가 */
.doodle-decoration {
  position: absolute;
  font-size: 2rem;
}

.doodle-star {
  top: -20px;
  right: -20px;
  transform: rotate(15deg);
}

.doodle-heart {
  bottom: -20px;
  left: -20px;
  transform: rotate(-15deg);
}

/* 애니메이션 */
@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(5deg);
  }
}

.logo-section {
  width: 80%;
  max-width: 300px;
  margin: 0 auto 1.5rem auto;
}

.logo-section img {
  width: 100%;
  height: auto;
}

.logo-section h1 {
  font-family: 'Orange Juice', cursive;
  font-size: clamp(4rem, 12vw, 5.5rem);
  color: #000000;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: normal;
}

h2 {
  color: #000000;
  margin: 1.2rem 0;
  padding: 0 0px;
  line-height: 1.3;
  font-weight: bold;
}

/* 모바일 최적화 */
@media (max-width: 480px) {
  .logo-section h1 {
    font-size: clamp(3.5rem, 10vw, 4.5rem);
  }

  h2 {
    font-size: clamp(1.5rem, 5vw, 2rem);
    margin: 1rem 0;
  }
}

/* 태블릿 최적화 */
@media (min-width: 481px) and (max-width: 768px) {
  .landing-container {
    width: 80%;
    max-width: 450px;
  }
}

/* 안전영역 확보 */
@supports (padding: max(0px)) {
  .landing-page {
    padding: max(20px, env(safe-area-inset-top))
             max(20px, env(safe-area-inset-right))
             max(20px, env(safe-area-inset-bottom))
             max(20px, env(safe-area-inset-left));
  }
}

.logo-text {
  font-family: 'Orange Juice', cursive;
  font-size: clamp(3.5rem, 10vw, 4.5rem);
  color: #000000;
  margin-bottom: 1rem;
  line-height: 1;
}

/* 배경 스타일 수정 */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* PC 화면 */
@media (min-width: 1024px) {
  .landing-container {
    max-width: 500px;
    padding: 3rem;
  }

  .logo-section h1 {
    font-size: clamp(4.5rem, 8vw, 5.5rem);
  }

  h2 {
    font-size: clamp(2rem, 4vw, 2.5rem);
  }
}

/* 태블릿 화면 */
@media (min-width: 768px) and (max-width: 1023px) {
  .landing-container {
    max-width: 450px;
    padding: 2.5rem;
  }

  .logo-section h1 {
    font-size: clamp(4rem, 7vw, 5rem);
  }

  h2 {
    font-size: clamp(1.8rem, 3.5vw, 2.3rem);
  }
}

/* 모바일 화면 */
@media (max-width: 767px) {
  .landing-container {
    width: 85%;
    max-width: none;
    padding: 1.5rem;
    margin: 15px auto;
  }

  .logo-section h1 {
    font-size: clamp(3.5rem, 10vw, 4rem);
  }

  h2 {
    font-size: clamp(1.5rem, 5vw, 1.8rem);
    padding: 0 5px;
    margin: 1rem 0;
  }

  .play-button {
    padding: 0.7rem 1.5rem;
    font-size: clamp(1rem, 4vw, 1.2rem);
    min-width: 180px;
  }
}

/* 작은 모바일 화면 */
@media (max-width: 360px) {
  .landing-container {
    width: 90%;
    padding: 1rem;
  }

  .logo-section h1 {
    font-size: clamp(3rem, 9vw, 3.5rem);
  }

  h2 {
    font-size: clamp(1.3rem, 4.5vw, 1.5rem);
  }
}

/* 노치 디자인 대응 */
@supports (padding: max(0px)) {
  .landing-page {
    padding: max(20px, env(safe-area-inset-top))
             max(20px, env(safe-area-inset-right))
             max(20px, env(safe-area-inset-bottom))
             max(20px, env(safe-area-inset-left));
  }
}

/* 높이가 낮은 화면 대응 */
@media (max-height: 600px) {
  .landing-container {
    padding: 1rem;
  }
}

/* Store 정보 스타일 수정 */
.store-info {
  margin-top: 2rem;
  text-align: center;
  position: relative;
}

.store-icons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 0.7;
}

.store-icons img {
  height: 40px;
  width: auto;
}

/* Coming Soon 오버레이 */
.store-info::after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1.2rem, 4vw, 1.5rem);
  color: #000;
  background: rgba(255, 255, 255, 0.7);
  padding: 0.3rem 1rem;
  border-radius: 15px;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

/* 모바일 대응 */
@media (max-width: 767px) {
  .store-info {
    margin-top: 1.5rem;
  }
  
  .store-icons img {
    height: 35px;
  }
} 