.drawing-window {
  position: relative;
  z-index: 1;
}

.drawing-area {
  position: relative;
  z-index: 2;
  background-color: transparent;
  padding: 10px;
  border-radius: 24px;
  border: 3px solid #000000;
  box-shadow: 4px 4px 0 #000000;
}

.drawing-area.drawer {
  background-color: transparent;
  padding: 10px;
  border-radius: 24px;
  border: 3px solid #000000;
  box-shadow: 4px 4px 0 #000000;
}

.drawing-tools {
  height: 40px;
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  box-shadow: 3px 3px 0 #000000;
}

.color-tools {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  flex-wrap: wrap;
  width: 96%;
  order: 2;
  padding: 0;
}

.tools-group {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  border-radius: 5px;
  width: 100%;
  height: 20px;
}

.color-button {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}


.pen-button {
  border-radius: 50%;
  cursor: pointer;
}

.eraser-button {
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
}

.clear-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #000000;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.2s;
}

.clear-button:hover {
  background-color: #ff0000;
}

.selected {
  box-shadow: 0 0 0 2px #666;
}

.canvas {
  border: 1px solid black;
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* 모바일 화면 */
@media (max-width: 767px) {
  .pen-button,
  .eraser-button {
    transform: scale(0.9);
  }
}

/* 태블릿 이상 화면 */
@media (min-width: 768px) {
  /* 이전 스타일 제거 */
}

/* Non-Drawer를 위한 오버레이 스타일 수정 */
.drawing-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  pointer-events: none;
}

.drawing-message {
  background: #000000;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 1.2rem;
}

/* Non-Drawer의 도구 비활성화 스타일 수정 */
.drawing-area:not(.drawer) .drawing-tools {
  opacity: 0.7;
}

/* 도구 버튼들의 pointer-events 제거 */
.color-button,
.pen-button,
.eraser-button,
.clear-button {
  cursor: pointer;
}

.color-picker {
  flex: 1;
  overflow-x: auto;
  margin: 0 10px;
  -webkit-overflow-scrolling: touch;
}

.color-picker::-webkit-scrollbar {
  display: none;  /* 스크롤바 숨기기 */
}

.color-slider {
  display: flex;
  min-width: min-content;
}

.color-option {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  transition: transform 0.2s;
  flex-shrink: 0;
}

.color-option:hover {
  transform: scale(1.2);
}

.color-option.selected {
  transform: scale(1.2);
  border: 2px solid #000000;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.color-slider-container {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 10px 0;
  touch-action: none;
}

.color-spectrum {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #ffffff;
  box-shadow: 0 0 0 1px #000000;
}

.color-picker-handle {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 0 1px #000000, 0 2px 4px rgba(0,0,0,0.2);
  top: 8px;
  cursor: pointer;
  transition: transform 0.1s;
  z-index: 1;
}

.color-picker-handle.dragging {
  transform: translateX(-50%) scale(1.2) !important;
}

.color-picker-handle:hover {
  transform: translateX(-50%) scale(1.1);
}

.basic-colors {
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.color-preset {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 0 0 1px #000000;
  cursor: pointer;
  transition: transform 0.2s;
}

.color-preset:hover {
  transform: scale(1.2);
}

.color-preset.selected {
  transform: scale(1.2);
  box-shadow: 0 0 0 2px #000000;
}

.color-slider-tools {
  height: 40px;
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-top: 10px;
  box-shadow: 3px 3px 0 #000000;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.drawing-tools {
  margin-bottom: 10px;
}

.drawing-tools-container {
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

.tools-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.pen-tools, .eraser-tools {
  display: flex;
  gap: 8px;
  align-items: center;
}

.color-slider-row {
  padding-top: 10px;
  width: 100%;
}
  