.game-screen {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-image: radial-gradient(#000000 0.5px, transparent 0.5px);
  background-size: 15px 15px;
  padding: env(safe-area-inset-top) 
           env(safe-area-inset-right)
           env(safe-area-inset-bottom)
           env(safe-area-inset-left);
  box-sizing: border-box;
  overflow-x: hidden;
}

.time-bar {
  width: 90%;
  max-width: 780px;
  font-size: 2rem;
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 50px;
  padding: 0.5rem;
  text-align: center;
  box-shadow: 4px 4px 0 #000000;
  margin: 1rem 0;
}

.players-list {
  width: 95%;
  max-width: 900px;
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 24px;
  padding: 1rem;
  margin: 0.5rem auto;
  box-shadow: 4px 4px 0 #000000;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
}

.player {
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 12px;
  padding: 0.4rem 0.8rem;
  box-shadow: 3px 3px 0 #000000;
}

.player:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 #000000;
}

.player-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  min-width: 0;
}

.player-number {
  flex: 0 0 auto;
  min-width: 1rem;
}

.player-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player-score {
  flex: 0 0 auto;
  white-space: nowrap;
}

.name-side-btn {
  font-size: 1.2rem;
}


/* 화이트보드 컨테이너 */
.whiteboard-container {
  width: 95%;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.current-keyword {
  position: relative;
  top: 10px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: #000;
  color: white;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.current-keyword span {
  color: #FFD700;
  margin-left: 10px;
  font-weight: bold;
}

/* 모바일 화면에서의 반응형 스타일 */
@media (max-width: 767px) {
  .current-keyword {
    font-size: 1.2rem;
    padding: 6px 16px;
  }
}

.drawing-player {
  background: #000000;
  color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 3px 3px 0 #000000;
}

.drawing-player .player-name,
.drawing-player .player-number,
.drawing-player .player-score {
  color: #ffffff;
}

.end-game {
  position: fixed;
  bottom: max(20px, env(safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
  max-width: 480px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 10;
}

.end-game button {
  width: 90%;
  max-width: 400px;
  height: 20px;
  border-radius: 25px;
  font-size: 1.5rem;
  background-color: #000000;
  color: #ffffff;
  border: 3px solid #000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.correct-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 24px;
  box-shadow: 4px 4px 0 #000000;
  padding: 1.5rem 2rem;
  z-index: 1000;
  animation: popIn 0.3s ease-out;
}

.correct-popup p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.correct-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.correct-actions button {
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 50px;
  box-shadow: 3px 3px 0 #000000;
  color: #000000;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.correct-actions button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 #000000;
}

.ranking-popup {
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 24px;
  box-shadow: 4px 4px 0 #000000;
  color: #000000;
  padding: 2rem;
  min-width: 280px;
}

.ranking-popup h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.ranking-list {
  margin-bottom: 2rem;
}

.ranking-item {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}

.ranking-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.ranking-actions button {
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 50px;
  box-shadow: 2px 2px 0 #000000;
  color: #000000;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  min-width: 80px;
  cursor: pointer;
  transition: transform 0.2s;
}

.ranking-actions button:hover {
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 0 #000000;
}

/* 모바일 화면 (768px 미만) */
@media (max-width: 767px) {
  .time-bar {
    font-size: 1.5rem;
    padding: 0.3rem;
  }

  .player {
    padding: 0.3rem 0.6rem;
  }

  .player-info {
    gap: 0.3rem;
  }

  .player-number,
  .player-name,
  .player-score {
    font-size: 0.85rem;
  }

  .name-side-btn {
    font-size: 1rem;
  }

  .ranking-popup {
    padding: 1.5rem;
    min-width: 250px;
  }

  .ranking-popup h2 {
    font-size: 1.8rem;
  }

  .ranking-item {
    font-size: 1.2rem;
  }

  .ranking-actions button {
    padding: 0.3rem 0.8rem;
    font-size: 1.1rem;
  }

  .end-game {
    width: 100%;
    padding: 0 0px;
    
  }

  .players-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    padding: 0.8rem;
  }

  .correct-popup {
    width: 85%;
    max-width: 300px;
    padding: 1rem 1.5rem;
  }


  .player-info {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .game-header .logo-text {
    font-size: 1.2rem;
  }

  .players-list {
    width: 90%;
    padding: 0.8rem;
  }

  .whiteboard-container {
    width: 90%;
  }

  .end-game button {
    width: 30%;
    font-size: 1.3rem;
  }
}

/* 태블릿 이상 화면 (768px 이상) */
@media (min-width: 768px) {
  .whiteboard-container {
    width: 60%;  /* 태블릿에서 폭 60%로 조정 */
    max-width: 600px;  /* 최대 폭도 줄임 */
    margin: 0 auto;
  }

  .end-game {
    width: 480px;
  }

  .time-bar {
    font-size: 2rem;
    padding: 0.5rem;
  }

  .player {
    padding: 0.5rem 0.6rem;
  }

  .player-info {
    gap: 0.5rem;
  }

  .ranking-popup {
    width: 70%;
    max-width: 600px;
    padding: 2rem;
  }

  .players-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 큰 화면 (1024px 이상) */
@media (min-width: 1024px) {
  .whiteboard-container {
    width: 55%;  /* 더 큰 화면에서는 약간 더 줄임 */
    max-width: 550px;
  }
}

/* 노치 디자인 대응 */
@supports (padding: max(0px)) {
  .game-screen {
    padding: max(20px, env(safe-area-inset-top))
             max(20px, env(safe-area-inset-right))
             max(20px, env(safe-area-inset-bottom))
             max(20px, env(safe-area-inset-left));
  }
}

.confirm-popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-popup {
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 24px;
  box-shadow: 4px 4px 0 #000000;
  padding: 2rem;
  text-align: center;
  max-width: 90%;
  width: 300px;
}

.confirm-popup p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.confirm-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.confirm-actions button {
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 50px;
  box-shadow: 3px 3px 0 #000000;
  color: #000000;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.confirm-actions button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 #000000;
}

/* 모바일 대응 */
@media (max-width: 767px) {
  .confirm-popup {
    padding: 1.5rem;
    width: 280px;
  }
  
  .confirm-popup p {
    font-size: 1.1rem;
    margin-bottom: 1.2rem;
  }
  
  .confirm-actions button {
    padding: 0.4rem 1.2rem;
  }
}

/* 게임 헤더 (로고) */
.game-header {
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
  height: 10px;
}


/* 팝업 오버레이 공통 스타일 */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@keyframes popIn {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.game-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.game-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.players-section {
  width: 95%;
  max-width: 900px;
}

.whiteboard-section {
  width: 95%;
  max-width: 900px;
}

/* 태블릿 화면 (768px 이상) */
@media (min-width: 768px) {
  .game-layout {
    gap: 1.5rem;
  }

  .players-section {
    width: 80%;
    max-width: 600px;
  }

  .whiteboard-section {
    width: 45%;
    max-width: 450px;
  }
}

/* 큰 화면 (1024px 이상) */
@media (min-width: 1024px) {
  .game-layout {
    gap: 2rem;
  }

  .players-section {
    width: 70%;
    max-width: 550px;
  }

  .whiteboard-section {
    width: 40%;
    max-width: 400px;
  }
}

/* 매우 큰 화면 (1440px 이상) */
@media (min-width: 1440px) {
  .players-section {
    width: 60%;
    max-width: 500px;
  }

  .whiteboard-section {
    width: 35%;
    max-width: 380px;
  }
}

.timer-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.timer-bar {
  flex-grow: 1;
  height: 10px;
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
}

.timer-progress {
  height: 100%;
  transition: width 0.95s linear;
}

.timer-text {
  min-width: 40px;
  text-align: right;
  font-weight: bold;
}

.infinity-icon {
  font-size: 24px;
  color: #666;
}

.keyword-input-section {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  z-index: 100;
  position: relative;
}

.current-keyword,
.answer-input {
  background-color: #000000;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: 3px solid #000;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  pointer-events: auto;
}

.answer-input form {
  margin: 0;
  padding: 0;
  width: 100%;
  pointer-events: auto;
}

.answer-input input {
  width: 100%;
  border: none;
  background: none;
  text-align: center;
  font-size: 1em;
  padding: 0;
  margin: 0;
  outline: none;
  color: white;
  pointer-events: auto;
  cursor: text;
}

.answer-input input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.current-keyword span {
  font-weight: bold;
  color: white;
}

.timer-container {
  margin-top: 10px;
  width: 100%;
}

.keyword-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.current-keyword span {
  font-weight: bold;
  color: white;
}

.skip-button {
  position: absolute;
  right: 0px;
  padding: 2px 8px;
  height: 24px; /* 높이 고정 */
  line-height: 20px; /* 텍스트 세로 중앙 정렬 */
  border-radius: 12px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 0.8em;
  cursor: pointer;
  transition: all 0.2s ease;
}

.skip-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}

.skip-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1a1a1a;
  border-radius: 15px;
  padding: 20px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.waiting-message {
  color: white;
  text-align: center;
  padding: 30px;
  font-size: 1.2em;
}

.end-popup {
  position: relative;
  padding: 20px;
  min-width: 300px;  /* 최소 너비 설정 */
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
  line-height: 1;
}

.close-button:hover {
  color: #000;
}

/* 기존 confirm-actions 스타일 확장 */
.end-options.confirm-actions {
  display: flex;
  flex-direction: column;  /* 버튼을 세로로 배치 */
  gap: 10px;
  margin-top: 10px;
}

.end-options button {
  width: 100%;  /* 버튼 너비 100% */
  padding: 10px 20px;
  font-size: 14px;
}

/* 기존 confirm-button과 cancel-button 스타일 사용 */

/* 새로운 settings-modal 스타일 */
.settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* 게스트를 위한 waiting-message 스타일 수정 */
.waiting-message {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.waiting-message p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

